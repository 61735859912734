
#id6453d162a52ebcacc7a7ec33 {
  #iu93 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
} 
#ik3f {
padding : 10px; 
min-height : 100px; 
} 
#i71w {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#i9t9 {
padding : 10px; 
min-height : 50px; 
width : 200px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iv25 {
color : black; 
width : 135.45455932617188px; 
min-height : 10auto; 
} 
#ipm36 {
padding : 10px; 
min-height : 100px; 
width : 20%; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#i81dl {
padding : 10px; 
display : block; 
font-size : 12px; 
font-weight : 500; 
font-family : Helvetica, sans-serif; 
} 
#ijrwk {
color : black; 
} 
#i5n3w {
color : black; 
width : 24px; 
} 
#iprxd {
color : black; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i4wgg {
min-height : 100px; 
padding : 10px; 
} 
#iwt3u {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
font-size : 64px; 
font-weight : 600; 
} 
#i7wai {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ireak {
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#i6cxc {
display : block; 
} 
#i4oh9 {
display : block; 
} 
#iz9xr {
display : block; 
} 

  @media only screen and (max-width: 992px) {#ipm36 {
width : 50%; 
}} 

  @media only screen and (max-width: 480px) {#ipm36 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iwt3u {
font-size : 32px; 
}} 

}
  